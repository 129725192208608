export const COUNTDOWN_TIMER_START = 5; // @TODO env?
export const WSP_DEBUG = false; // @TODO env?
export const ESP_DEBUG = false; // @TODO env?

export const transformErrorMessage = (error: Error): string => {
  if (error.message.startsWith('Bad Request: ')) {
    return error.message.replace('Bad Request: ', '');
  }
  return error.message;
};

export const transformAnyError = (error: Error | string | unknown): string => {
  if (error instanceof Error) {
    return transformErrorMessage(error);
  }
  if (typeof error === 'string' && error.startsWith('Bad Request: ')) {
    return error.replace('Bad Request: ', '');
  }
  return String(error);
}