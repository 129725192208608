'use client';
import { useWebSocket } from '@/components/websocket/WebSocketProvider';
import { delay, delayedSync } from '@/helper/delay';
import { ESP_DEBUG } from '@/helper/errors';
import { useAsyncEffect } from '@/helper/useAsyncEffect';
import { useInputNavigation } from '@/helper/useInputNavigation';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface ProGloveWebSocketEvent extends Event {
  data: string;
}

export type ProGloveDeviceSerial = string;

export interface ProGloveEvent {
  api_version: string;
  event_type: string;
  event_id: string;
  time_created: number;
  disconnect?: 'all' | string[];
  connect?: boolean;
}

export interface ProGloveEventScan extends ProGloveEvent {
  event_type: 'scan';
  scan_code: string;
  scan_bytes: string;
  device_serial: ProGloveDeviceSerial;
  device_model: string;
  gateway_serial: string;
}

export interface ProGloveEventButtonPress extends ProGloveEvent {
  event_type: 'button_pressed';
  trigger_gesture: ProGloveTriggerGesture;
  device_serial: ProGloveDeviceSerial;
  gateway_serial: string;
}

export interface ProGloveEventScannerState extends ProGloveEvent {
  event_type: 'scanner_state';
  device_connected_state: 'STATE_DISCONNECTED' | string;
  device_disconnect_reason: 'UNKNOWN' | string;
  device_serial: ProGloveDeviceSerial;
}

export interface ProGloveCommand {
  api_version: string;
  event_type: string;
  event_id: string;
  time_created: number;
}

// https://docs.proglove.com/en/screen-templates.html#templates-84-36459
export enum ProGloveDisplayTemplateId {
  PG1 = 'PG1',
  PG1A = 'PG1A',
  PG1C = 'PG1C',
  PG1I = 'PG1I',
  PG1E = 'PG1E',
  PG2 = 'PG2',
  PG2A = 'PG2A',
  PG2C = 'PG2C',
  PG2I = 'PG2I',
  PG2E = 'PG2E',
  PG3 = 'PG3',
}

export enum ProGloveWorkerFeedbackActionId {
  FEEDBACK_POSITIVE = 'FEEDBACK_POSITIVE',
  FEEDBACK_NEGATIVE = 'FEEDBACK_NEGATIVE',
  FEEDBACK_SPECIAL_YELLOW = 'FEEDBACK_SPECIAL_YELLOW',
  FEEDBACK_SPECIAL_PURPLE = 'FEEDBACK_SPECIAL_PURPLE',
  FEEDBACK_SPECIAL_CYAN = 'FEEDBACK_SPECIAL_CYAN',
}

export enum ProGloveTriggerGesture {
  TRIGGER_SINGLE_CLICK = 'TRIGGER_SINGLE_CLICK',
  TRIGGER_DOUBLE_CLICK = 'TRIGGER_DOUBLE_CLICK',
  TRIGGER_TRIPLE_CLICK = 'TRIGGER_TRIPLE_CLICK',
}

export interface ProGloveCommandDisplayField {
  display_field_id: number;
  display_field_header: string;
  display_field_text: string;
  display_field_header_right?: string;
}

export interface ProGloveCommandDisplay extends ProGloveCommand {
  api_version: '1.2';
  event_type: 'display!';
  device_serial: ProGloveDeviceSerial;
  display_template_id: ProGloveDisplayTemplateId;
  display_fields: ProGloveCommandDisplayField[];
  time_validity_duration?: number;
  display_refresh_type?: 'DEFAULT' | 'FULL_REFRESH' | 'PARTIAL_REFRESH';
}

export interface ProGloveCommandWorkerFeedback extends ProGloveCommand {
  device_serial: ProGloveDeviceSerial,
  feedback_action_id: ProGloveWorkerFeedbackActionId,
}

export interface ProGloveCommandButtonBlocking extends ProGloveCommand {
  device_serial: ProGloveDeviceSerial,
  time_validity_duration?: number,
  trigger_block_state?: boolean,
}

export const isProGloveEventScan = (event: ProGloveEvent): event is ProGloveEventScan => {
  return event.event_type === 'scan';
};

export const isProGloveEventButtonPress = (event: ProGloveEvent): event is ProGloveEventButtonPress => {
  return event.event_type === 'button_pressed';
};

export const isProGloveEventScannerState = (event: ProGloveEvent): event is ProGloveEventScannerState => {
  return event.event_type === 'scanner_state';
};

export interface ProGloveDisplayUpdate {
  display_template_id: ProGloveDisplayTemplateId;
  display_fields: ProGloveCommandDisplayField[];
  display_refresh_type?: 'DEFAULT' | 'FULL_REFRESH' | 'PARTIAL_REFRESH';
}

export type ScanHandler = (scanCode: string) => null | React.MutableRefObject<HTMLFormElement | null>;
export type ButtonPressHandler = (triggerGesture: ProGloveTriggerGesture) => void;

interface ProGloveContextState {
  connected: boolean;
  connectedOnce: boolean;
  scannerConnected: boolean;
  loading: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  scannerConnectivity: () => void;
  updateDisplay: (update: ProGloveDisplayUpdate, duration?: number) => void;
  workerFeedback: (feedbackActionId: ProGloveWorkerFeedbackActionId) => void;
  buttonBlocking: (state?: boolean, duration?: number) => void;
  addScanListener: (handler: ScanHandler) => void;
  addButtonPressListener: (handler: ButtonPressHandler) => void;
  removeScanListener: (handler: ScanHandler) => void;
  removeButtonPressListener: (handler: ButtonPressHandler) => void;
}

export const ProGloveContext = createContext<ProGloveContextState | null>(null);

export function useProGlove(): ProGloveContextState {
  const context = useContext(ProGloveContext);
  if (context === null) {
    throw new Error('useProGlove must be used within ProGloveProvider');
  }

  return context;
}

interface ProGloveProviderProps {
  children: React.ReactNode;
}

export function ProGloveProvider({ children }: ProGloveProviderProps): React.JSX.Element {
  const { ws, connected, connect: wsConnect, disconnect } = useWebSocket();
  const [scanHandler, setScanHandler] = useState<ScanHandler[]>([]);
  const [buttonPressHandler, setButtonPressHandler] = useState<ButtonPressHandler[]>([]);
  const [connectedOnce, setConnectedOnce] = useState<boolean>(false);
  const [scannerConnected, setScannerConnected] = useState<boolean>(false);
  const [scannerDeviceSerial, setScannerDeviceSerial] = useState<ProGloveDeviceSerial | null>(null);

  const inputNavigation = useInputNavigation();

  const scannerConnectivity = useCallback(() => {
    if (ws.current !== null) {
      const scannerConnectivityCommand: ProGloveEvent = {
        api_version: '1.0',
        event_type: 'scanner_connectivity!',
        event_id: uuidv4(),
        time_created: Date.now(),
        disconnect: 'all',
        connect: true,
      };

      console.log('proglove websocket send: ', scannerConnectivityCommand);
      ws.current.send(JSON.stringify(scannerConnectivityCommand));
    }
  }, [ws]);

  const connect = useCallback(async () => {
    const maxRetries = 5;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        await wsConnect();
        break;
      } catch (e) {
        if (ESP_DEBUG) {
          console.log(e, 'Could not connect to WebSocket, retrying');
        }
        retries++;
      }

      await delay(3000 * retries);
    }

    if (ws.current !== null) {
      setConnectedOnce(true);

      scannerConnectivity();
    }
  }, [scannerConnectivity, ws, wsConnect]);

  const updateDisplay = useCallback(
    (update: ProGloveDisplayUpdate, duration: number = 0) => {
      if (ws.current !== null && scannerDeviceSerial !== null) {
        const displayCommand: ProGloveCommandDisplay = {
          api_version: '1.2',
          event_type: 'display!',
          event_id: uuidv4(),
          time_created: Date.now(),
          device_serial: scannerDeviceSerial,
          time_validity_duration: duration,
          display_template_id: update.display_template_id,
          display_fields: update.display_fields,
          display_refresh_type: update.display_refresh_type,
        };

        ws.current.send(JSON.stringify(displayCommand));
      }
    },
    [scannerDeviceSerial, ws],
  );

  const workerFeedback = useCallback(
    (feedbackActionId: ProGloveWorkerFeedbackActionId) => {
      if (ws.current !== null && scannerDeviceSerial !== null) {
        const feedbackCommand: ProGloveCommandWorkerFeedback = {
          api_version: '1.0',
          event_type: 'feedback!',
          event_id: uuidv4(),
          time_created: Date.now(),
          device_serial: scannerDeviceSerial,
          feedback_action_id: feedbackActionId,
        };

        ws.current.send(JSON.stringify(feedbackCommand));
      }
    },
    [scannerDeviceSerial, ws],
  );

  const buttonBlocking = useCallback(
      (blockState: boolean = true, duration: number = 0) => {
        if (ws.current !== null && scannerDeviceSerial !== null) {
          const triggerBlockCommand: ProGloveCommandButtonBlocking = {
            api_version: '1.0',
            event_type: 'trigger_block!',
            event_id: uuidv4(),
            time_created: Date.now(),
            device_serial: scannerDeviceSerial,
            time_validity_duration: duration,
            trigger_block_state: blockState,
          };

          ws.current.send(JSON.stringify(triggerBlockCommand));
        }
      },
      [scannerDeviceSerial, ws],
  );

  const messageListener = useCallback(
    (event: ProGloveWebSocketEvent) => {
      const data: ProGloveEvent = JSON.parse(event.data);

      if (isProGloveEventScan(data)) {
        if (inputNavigation.execSync(data.scan_code)) {
          return;
        }

        scanHandler.forEach((handler) => {
          const refForm = handler(data.scan_code);

          if (refForm !== null) {
            delayedSync(() => {
              refForm.current?.requestSubmit();
            });
          }
        });

        return;
      }

      if (isProGloveEventButtonPress(data)) {
        buttonPressHandler.forEach((handler) => {
          handler(data.trigger_gesture);
        });

        return;
      }

      if (isProGloveEventScannerState(data)) {
        if (data.device_connected_state === 'STATE_DISCONNECTED') {
          // @TODO check device serial, it might be another scanner
          console.log('scanner disconnected, sending connectivity command');

          setScannerConnected(false);
          setScannerDeviceSerial(null);

          if (ws.current !== null) {
            const scannerConnectivityCommand: ProGloveEvent = {
              api_version: '1.0',
              event_type: 'scanner_connectivity!',
              event_id: uuidv4(),
              time_created: Date.now(),
              connect: true,
            };

            ws.current.send(JSON.stringify(scannerConnectivityCommand));
          }

          return;
        }

        if (data.device_connected_state === 'STATE_CONNECTED') {
          setScannerConnected(true);
          setScannerDeviceSerial(data.device_serial);
        }
      }
    },
    [inputNavigation, scanHandler, buttonPressHandler, ws],
  );

  // @TODO need refactor
  const [, loading] = useAsyncEffect(
    async () => {
      await connect();

      if (ws.current === null) {
        return;
      }

      ws.current.removeEventListener('message', messageListener);
      ws.current.addEventListener('message', messageListener);
    },
    async () => {
      await disconnect();

      if (ws.current !== null) {
        ws.current.removeEventListener('message', messageListener);
      }
    },
    [],
  );

  // @TODO need refactor
  useEffect(() => {
    if (ws.current === null) {
      return;
    }

    ws.current.removeEventListener('message', messageListener);
    ws.current.addEventListener('message', messageListener);

    return () => {
      if (ws.current !== null) {
        // @TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ws.current.removeEventListener('message', messageListener);
      }
    };
  }, [messageListener, ws]);

  const addScanListener = useCallback((handler: ScanHandler) => {
    setScanHandler((prev) => [...prev, handler]);
  }, []);

  const addButtonPressListener = useCallback((handler: ButtonPressHandler) => {
    setButtonPressHandler((prev) => [...prev, handler]);
  }, []);

  const removeScanListener = useCallback((handler: ScanHandler) => {
    setScanHandler((prev) => prev.filter((hdlr) => hdlr !== handler));
  }, []);

  const removeButtonPressListener = useCallback((handler: ButtonPressHandler) => {
    setButtonPressHandler((prev) => prev.filter((hdlr) => hdlr !== handler));
  }, []);

  return (
    <ProGloveContext.Provider
      value={{
        connected,
        loading,
        connect,
        connectedOnce,
        scannerConnected,
        disconnect,
        scannerConnectivity,
        updateDisplay,
        workerFeedback,
        buttonBlocking,
        addScanListener,
        addButtonPressListener,
        removeScanListener,
        removeButtonPressListener,
      }}
    >
      {children}
    </ProGloveContext.Provider>
  );
}
